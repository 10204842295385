<template>
  <div class="app-container">
    <navBar :title="'设置'" @handleGoBack="handleGoBack">
      <template v-slot:icon1>
        <van-icon name="plus" @click="goRulePage(0)" />
        <!--        <i class="iconfont icon-gengduo" style="font-size: 20px"></i>-->
      </template>
    </navBar>

    <!-- <div class="content1">公司总人数389人，参与考勤382人</div> -->

    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="content2">
        <div class="item-list mrg-b-20" v-for="item in list" :key="item.id">
          <div class="top mrg-b-20 flex-spa-bet">
            <div class="tit">{{ item.groupName }}</div>
            <div class="delete-btn flex-ver-cen" @click="handleDelete(item.id)">
              <van-icon name="delete-o" class="mrg-r-10" />删除
            </div>
          </div>
          <div class="center mrg-b-25">
            <div class="flex mrg-b-15">
              <div class="member mrg-r-30">成员：{{ item.applyCount }}人</div>
              <div class="leader">负责人：{{ item.createBy }}</div>
            </div>
            <div class="time mrg-b-15">考勤时间：{{ item.upTime }}-{{ item.downTime }}</div>
            <div class="address mrg-b-15">考勤地址：{{ item.officeAddress }}</div>
            <div class="wifi" v-if="item.wifiInfoList">办公WIFI：{{ item.wifiInfoList[0].wifiIp || '--' }}</div>
          </div>

          <div class="bottom flex">
            <div class="item-btn" @click="goRulePage(item.id)">修改规则</div>
            <!--            <div class="item-btn">修改成员及负责人</div>-->
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>
<script>
import { fetchCheckRuleList, deleteRuleList } from '@/api/hr/hrAttendance';
export default {
  data() {
    return {
      list: [],
      loading: false,
      finished: true,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    async getList() {
      const result = await fetchCheckRuleList();
      this.list = result.data;
    },

    onLoad() {},

    goRulePage(id) {
      if (id) {
        this.$router.push('/attendance/ruleSet/' + id);
      } else {
        this.$router.push('/attendance/ruleSet/' + 0);
      }
    },

    handleDelete(ids) {
      this.$dialog
        .confirm({
          title: '删除',
          message: '是否确认删除此数据?',
          className: 'appendix-del-dialog',
          width: '300',
        })
        .then(async () => {
          await deleteRuleList(ids);
          this.$toast.success('删除成功');
          this.getList();
        })
        .catch(() => {
          // on cancel
        });
    },

    handleGoBack() {
      this.$router.replace('/attendance');
        this.$router.go(-1)
    },
  },
};
</script>
<style lang="scss" scoped>
.content1 {
  padding: 20px 30px;
  font-size: 24px;
  color: #999999;
}

.content2 {
  .item-list {
    background: #fff;
    padding: 30px 40px;
    .top {
      .tit {
        font-size: 32px;
        font-weight: bold;
        color: #333333;
      }

      .delete-btn {
        font-size: 24px;
        color: #999999;
      }
    }

    .center {
      font-size: 26px;
      color: #777777;
      border-bottom: 1px solid #ececec;
      padding-bottom: 45px;
    }
    .bottom {
      justify-content: end;
      .item-btn {
        padding: 0 20px;
        height: 60px;
        line-height: 60px;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #54afff;
        font-size: 24px;
        color: #54afff;
        margin-left: 30px;
        cursor: pointer;
      }
    }
  }
}
</style>
